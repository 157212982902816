import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home/home.vue'
import TemplateView from '@/views/templates/template.vue'
import PcHomeView from '@/views/home/pc_home.vue'

Vue.use(VueRouter)


// var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/index',
    name: 'index',
    component: HomeView
  },
  {
    path: '/pc',
    name: 'pc_home',
    component: PcHomeView
  },
  {
    path: '/share',
    name: 'share',
    component: TemplateView
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})


export default router
