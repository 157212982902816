<template>
  <div>
    <div v-if="showPc == 0">
      <pc_home></pc_home>
    </div>
    <div v-else-if="showPc == 1">
      <home></home>
    </div>
    <div v-else>
      <share></share>
    </div>
  </div>
</template>

<script>
import home from './views/home/home.vue';
import pc_home from './views/home/pc_home.vue';
import share from './views/templates/template.vue';
export default{
  data(){
    return {
      showPc: 0,
      isWeiXin: false
    }
  },
  components:{
    home,pc_home,share
  },
  // 禁止web端屏幕缩放
  created() {
    window.addEventListener("mousewheel",function (event) {
      if (event.ctrlKey === true || event.metaKey) {
        event.preventDefault();
      }},{ passive: false })
  },
  methods:{
    getUrlKey: function (name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    },
    openInBrowser(){
      const agent = window.navigator.userAgent;
      const isIso = /iPad|iPhone/.test(agent)
      if(isIso){
        let a = document.createElement('a');
         a.href = "https://apps.apple.com/cn/app/i%E8%AF%B4%E8%8B%B1%E8%AF%AD/id6497169872";
         a.click();
      }else{
         let a = document.createElement('a');
         a.href = "https://oss.ispeak.fun/download/android/ispeak-latest.apk";
         a.click();
         // window.reload();
      }
    }
  },
  mounted(){
    this.isWeiXin = /MicroMessenger/i.test(navigator.userAgent); 
    setInterval(()=>{
      var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if(location.href.includes('share')){
        this.showPc = 2
      }else if(parseInt(w) > 850 && !location.href.includes('share')){
        this.showPc = 0
      }else if(parseInt(w) < 850 && !location.href.includes('share')){
        this.showPc = 1
      }
    },100)
  },
}
</script>


<style>
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
